import React from 'react'

export default function Aboutus() {
  return (
    <div style={{display:'flex',justifyContent:'center'}}>
      <div className='about-us'>
        <h1 className='about-title'>Why Choose Us?</h1>
        <h4 className='about-sub'>For App Development</h4>
        <div className='about-item-div'>
            <div className='about-item'>
                <img src="https://cdn-icons-png.flaticon.com/128/6898/6898786.png" alt="" />
                <h2>UI/UX Design</h2>
                <span>Designing interfaces that are effective and easy to use by users and customers</span>
            </div>
            <div className='about-item'>
                <img src="https://cdn-icons-png.flaticon.com/128/6899/6899344.png" alt="" />
                <h2>Cross Platform</h2>
                <span>An Cross platform that allows users to use it on mobile phones both Ios & Android or on websites. </span>
            </div>
            <div className='about-item'>
                <img src="https://cdn-icons-png.flaticon.com/128/6898/6898935.png" alt="" />
                <h2>Referrals</h2>
                <span>Friend networking through referral policies, Allows users share the app and earn reward </span>
            </div>
            <div className='about-item'>
                <img src="https://cdn-icons-png.flaticon.com/128/6899/6899312.png" alt="" />
                <h2>Team</h2>
                <span>Team consists of hard-working, dedicated and skilled custom fantasy app developers. </span>
            </div>
            <div className='about-item'>
                <img src="https://cdn-icons-png.flaticon.com/128/6898/6898859.png" alt="" />
                <h2>Management</h2>
                <span>Our team will manage your app and server with database maintenance and setup</span>
            </div>
            
        </div>
      </div>
    </div>
  )
}
