import React from 'react'

export default function Footer() {
  return (
    <div>
    <div style={{backgroundColor : 'rgb(187, 187, 187)' , height : '2px' , margin : '20px'}}></div>
      <div className='footer' >
      <div className='footer-top'>

        <h1 style={{fontFamily : 'Light'  , fontSize : '40px' , color : 'rgb(187, 187, 187)'}}>
          Nod D.
        </h1>
        <p style={{fontFamily : 'All'  , fontSize : '15px' , color : 'rgb(187, 187, 187)'}}>
          Contact Us For Custom Orders.
          We Assure You Top Notch Quality And Assistance
        </p>
     </div>
     <div className='space'></div>
     <button onClick={()=> window.open("https://wa.me/918618468216", "_blank")} className='whatsapp-btn'>
      <img className='whatsapp-img' src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/479px-WhatsApp_icon.png" alt="" />
      Whatsapp
     </button>
     <button onClick={()=> window.open("https://www.fiverr.com/tigerart", "_blank")} className='whatsapp-btn'>
      <img className='fiverr-img' src="https://freelogopng.com/images/all_img/1656738037fiverr-icon-png.png" alt="" />
      Fiverr
     </button>
     <button onClick={()=> window.open("", "_blank")} className='whatsapp-btn'>
      <img className='fiverr-img' src="https://cdn-icons-png.flaticon.com/512/8945/8945503.png" alt="" />
      Privacy
     </button>
     <button onClick={()=> window.location ="mailto:noddevelopers@gmail.com"} className='whatsapp-btn'>
      <img className='fiverr-img' src="https://cdn-icons-png.flaticon.com/128/732/732200.png" alt="" />
      Mail
     </button>
    </div>
    <div style={{backgroundColor : 'rgb(187, 187, 187)' , height : '2px' , margin : '20px'}}></div>
    <div style={{margin : '20px'}}>
      <h1 style={{fontFamily : 'All' , color : 'rgb(187, 187, 187)' , fontSize : '12px'}}>
        Copyright © 2024 NodDevelopers. All Rights Reserved.
      </h1>
    </div>
    </div>

  )
}
