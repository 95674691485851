import React from 'react'

export default function Showcase() {
    const [view,setview] = React.useState(0);
    const click1 = ()=> {
      setview(1);
    }
    const click2 = ()=> {
      setview(2);
    }
    const click3 = ()=> {
      setview(3);
    }
    const click4 = ()=> {
      setview(4);
    }
    const closeclick = ()=> {
      setview(0);
    }
  return (
    <div>
      {/* <h2 className='feedhead'>What Our Clients Said About Us</h2> */}

      <div className='about-us'>
        <h1 className='about-title'>Reviews</h1>
        <h4 className='about-sub'>What Our Clients Said About Us</h4>
        </div>

      <div className='showcase'>
        <div onClick={click1} className='showcase-1 showcase1' style={{animationName:'square1'}}>
            <div style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-between'}}>
              <div className='profile-div'>
                <img className='round-img' src="https://i.pinimg.com/736x/df/ee/81/dfee81d3be1ed3dffdd248110f03d7d0.jpg" alt="" />
                <h4 className='profile-title'>Client 1</h4>
                <span className='profile-sub'>Mumbai</span>
                <p className='profile-des' >"Loved the work of the developer , demo descirptionLoved the work of the developer , demo descirptionLoved the work of the developer , demo descirptionLoved the work of the developer , demo descirptionLoved the work of the developer , demo descirption"</p>
              </div>


               <div style={{display:'flex', flexDirection:'column',justifyContent:'end'}}>
                  <div onClick={click1} className='item-profile-title1'>
                   <h1 >View</h1>
                  </div>
                </div>
            </div>
                
        </div>
       
        <div onClick={click2} className='showcase-2 showcase2'style={{animationName:'square2'}}>
        <div style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-between'}}>
              <div className='profile-div'>
                <img className='round-img' src="https://insertface.com/fb/912/business-professional-attire-911759-qzy7d-fb.jpg" alt="" />
                <h4 className='profile-title'>Client 2</h4>
                <span className='profile-sub'>Bengaluru</span>
                <p className='profile-des' >"Loved the work of the developer , demo descirptionLoved the work of the developer , demo descirptionLoved the work of the developer , demo descirptionLoved the work of the developer , demo descirptionLoved the work of the developer , demo descirption"</p>
              </div>


               <div style={{display:'flex', flexDirection:'column',justifyContent:'end'}}>
                  <div onClick={click2} className='item-profile-title1'>
                   <h1>View</h1>
                  </div>
                </div>
            </div>
        </div>
        <div onClick={click3} className='showcase-2 showcase3'style={{animationName:'square3'}}>
        <div style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-between'}}>
              <div className='profile-div'>
                <img className='round-img' src="https://img.freepik.com/premium-photo/portrait-smile-woman-excited-surprise-cheerful-girl-isolated-white-studio-background-face-female-model-person-with-facial-expression-omg-wow-with-joy-cheerful-winning_590464-162559.jpg?w=360" alt="" />
                <h4 className='profile-title'>Client 3</h4>
                <span className='profile-sub'>Haryana</span>
                <p className='profile-des' >"Loved the work of the developer , demo descirptionLoved the work of the developer , demo descirptionLoved the work of the developer , demo descirptionLoved the work of the developer , demo descirptionLoved the work of the developer , demo descirption"</p>
              </div>


               <div style={{display:'flex', flexDirection:'column',justifyContent:'end'}}>
                  <div onClick={click3} className='item-profile-title1'>
                   <h1>View</h1>
                  </div>
                </div>
            </div>
        </div>
        <div onClick={click4} className='showcase-2 showcase4'style={{animationName:'square4',animationDirection:'reverse'}}>
        <div style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-between'}}>
              <div className='profile-div'>
                <img className='round-img' src="https://thumbs.dreamstime.com/b/happy-man-okay-sign-portrait-white-background-showing-31418338.jpg" alt="" />
                <h4 className='profile-title'>Client 4</h4>
                <span className='profile-sub'>Chennai</span>
                <p className='profile-des' >"Loved the work of the developer , demo descirptionLoved the work of the developer , demo descirptionLoved the work of the developer , demo descirptionLoved the work of the developer , demo descirptionLoved the work of the developer , demo descirption"</p>
              </div>


               <div style={{display:'flex', flexDirection:'column',justifyContent:'end'}}>
                  <div onClick={click4} className='item-profile-title1'>
                   <h1>View</h1>
                  </div>
                </div>
            </div>
        </div>
        {
            view !== 0?(<div className='showcase-3' style={view === 1?{backgroundColor:'#00d0d0'}:{backgroundColor:'#ffffff'}}>

<div style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-between'}}>
              <div className='profile-div'>
              <div style={{display:'flex',justifyContent:'space-between'}}>
              <img className='round-img' src={view === 1? ('https://i.pinimg.com/736x/df/ee/81/dfee81d3be1ed3dffdd248110f03d7d0.jpg'):(view === 2? ('https://insertface.com/fb/912/business-professional-attire-911759-qzy7d-fb.jpg'):(view === 3? ('https://img.freepik.com/premium-photo/portrait-smile-woman-excited-surprise-cheerful-girl-isolated-white-studio-background-face-female-model-person-with-facial-expression-omg-wow-with-joy-cheerful-winning_590464-162559.jpg?w=360'):('https://thumbs.dreamstime.com/b/happy-man-okay-sign-portrait-white-background-showing-31418338.jpg')))} alt="" />

  <img onClick={closeclick} style={{height:'30px',width:'30px',margin:'5px',}} src="close-button.png" alt="" />
</div>
                <h4 className='profile-title'>{view === 1? ('Client1'):(view === 2? ('Client 2'):(view === 3? ('Client 3'):('client 4')))}</h4>
                <span className='profile-sub'>{view === 1? ('Mumbai'):(view === 2? ('Bengaluru'):(view === 3? ('Haryana'):('Chennai')))}</span>
                <p className='profile-dess' >"Loved the work of the developer , demo descirptionLoved the work of the developer , demo descirptionLoved the work of the developer , demo descirptionLoved the work of the developer , demo descirptionLoved the work of the developer , demo descirption"</p>
              </div>
              </div>
              
            </div>):(null)
        }
        
      </div>
    </div>
  )
}
