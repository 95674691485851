import './App.css';
import Navbar from './components/Navbar';
import "./fonts/WarowniaBlk.otf";
import './fonts/PaytoneOne-Regular.ttf'; 
import Galleryview from './components/Galleryview';
import Footer from './components/Footer';
import Page from './components/Page';
import Feedback from './components/Feedback';
import Showcase from './components/Showcase';
import Aboutus from './components/Aboutus';
// import React from 'react';
import React, { useEffect } from 'react';
import Points from './components/Points';
import Accordion from './components/Accordion';

function App() {

  const [isShown, setIsShown] = React.useState(false);
  const [isClass, setIsClass] = React.useState(false);
  const [isUrl, setIsUrl] = React.useState('none');

  var item1 = 'Nod11';
  var item2 = 'NodoTrade';

  const handleClick = event => {
    setIsShown(true);
    setIsClass(event.target.className);
    
    if (event.target.className === 'gallery-vid1' ) {
      setIsUrl(item1)

    } else if (event.target.className === 'gallery-vid2') {
      setIsUrl(item2)
    } else {
      setIsUrl('xx')
    };
  
    window.history.pushState(null, null, `${window.location.pathname}`);
    
  }




  useEffect(() => {
    if (window.location.pathname !== '/'){
      setIsShown(true);
      if (window.location.pathname === '/'+item1) {
        setIsClass('gallery-vid1');
      setIsUrl(item1);
      } else if (window.location.pathname === '/'+item2) {
        setIsClass('gallery-vid2');
      setIsUrl(item2);
      } else {
        setIsShown(false);
        window.history.pushState(null, null, `/`);
      }
    }
    console.log(isUrl , isClass);
    const handleBackPress = () => {
      setIsShown(false); // Set isShown to false on back press
      // window.history.pushState(null, null, window.location.pathname);
    setIsClass(false);
    
      
    };
    
    window.addEventListener('popstate', handleBackPress);

    return () => {
      window.removeEventListener('popstate', handleBackPress); // Cleanup function
    };

    
  }, [isUrl,isClass,item1,item2]);


  return (
    <>
<Navbar/>
<Galleryview isShown={isShown} handleClick={handleClick}/>
{/* {isClass !== false && <Page visible={isShown} isClass={isClass} />} */}
{ isUrl !== 'none' && isClass !== false && <Page visible={isShown} isClass={isClass} isUrl={isUrl} />}
<Aboutus/>
<Points/>
<Showcase/>
<Accordion/>
<Feedback/>
<Footer/>

    </>
  );
}

export default App;
