import React, { useState } from 'react';
function Accordion() {
    const items = [{
        title: "1. How to determine the cost of a app?",
        content: "The cost of the app depends on your needs and requirements like the size of the app, the features you wish to incorporate and whether you want to make it available on IOS or android or Web or both platforms. "
     },
     {
        title: "2. What are some of the benefits of app development?",
        content: "They help grow your customer base by attracting new business towards your company. They help build brand recognition and increase it’s awareness. They attract more traffic towards your website and help drive up sales. "
     },
     {
        title: "3. Why choose Nod Developers as your app development company?",
        content: "Our professionals are highly experienced individuals who have excelled in this field for a long time. They develop the most versatile and dynamic apps with the help of their creative ideas and never compromise on the quality that they provide. They are very time efficient and ensure prompt delivery of services."
     },{
        title: "4. Does Nod Developers priortise customer service?",
        content: "Yes, Absolutely. Customer satisfaction is of the utmost importance to us. Any customer that has availed our services will always back our claims. Support executives are available 24x7 to help you with all your queries and to make the development process easier. "
     }];
   const [activeIndex, setActiveIndex] = useState(0);
   const handleClick = (index) => {
      setActiveIndex(index === activeIndex ? -1 : index);
   };
   return (
      <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>

<div className='about-us'>
    <div style={{height:'15px'}}></div>
        <h1 className='about-title'>FAQ</h1>
        <h4 className='about-sub'>Frequently Asked Questions</h4>
        </div>

         {items.map((item, index) => (
            <div className='accord-div' onClick={() =>handleClick(index)} key={item.title}>
                <div style={{display:'flex',justifyContent:'space-between',width:'99%',alignItems:'center'}}>
               <button className='accord-btn' onClick={() =>handleClick(index)}>{item.title}</button>
               {index === activeIndex ? (
                <img style={{width:'30px',height:'30px'}} src="https://cdn-icons-png.flaticon.com/128/753/753322.png" alt="" />
               ):(
                <img style={{width:'30px',height:'30px'}} src="https://cdn-icons-png.flaticon.com/128/753/753317.png" alt="" />
               )}
               
                </div>
               {index === activeIndex && <p className='accord-p'>{item.content}</p>}
            </div>
         ))}
      </div>
   );
}
export default Accordion;