import React, { useEffect, useState } from 'react'
export default function Page({visible,isClass,isUrl}) {

    const [isShown, setIsShown] = useState(0);
    const [isopen, setisopen] = useState(true);

    const nodoTradeFeature = 'Its a app like Probo , A opinion Trading app\n\n which has yes and no bidding option\n\nAll category available like\n\nSports\n\nCricket\n\nFootball\n\nStocks\n\nCrypto\n\nYoutube\n\nEntertainment\n\nAdmin panel also available\n\nChating system also implemented\n\nLeaderboard also available\n\nWithdraw and Deposit\n\nAnd bonus through Refferal Program\n\nAdmin panel\n\nAndroid + Ios\n\nFor More details Contact Me';
    const nod11Feature = 'Flutter App (DART)\n\nAdmin Available (Easy to Use)\n\nTutorial Also Available\n\nIos + Android\n\nCode On Request\n\nLight Weight\n\nHigh Quality\n\nFuture Updates Included\n\nEnd to End Support\n\nMain Features\n\n-All International and Local Matches\n\n-Contests\n\n-Leaderboard\n\n-Custom Leaderboard\n\n-Withdraw\n\n-KYC\n\n-Deposit\n\n-Crypto , Upi etc\n\n-Chat Support\n\n-Pass System\n\n-Refer Bonus\n\n-Free App submission\n\nSo On\n\nJust message me ill give full information';
    // const initialVideoSource = isClass === 'gallery-vid1' ? 'fantasythumb.mp4' : 'Probovid.mp4';
    // const [videoSource, setVideoSource] = useState(initialVideoSource);

    const demolink = isClass === 'gallery-vid1'? ('https://Jeetna11.com'):('https://play.google.com/store/apps/details?id=com.cointrasy.winjoy');
    const fiverrDlink = isClass === 'gallery-vid1'? ('https://www.fiverr.com/s/95kxkA'):('https://www.fiverr.com/s/dxrgEa');


    const handleClick = event => {
      if (isShown < 3) {
        setIsShown(prevState => prevState + 1);       
      }
    }
    const handleClicks = event => {
      if (isShown > 0) {
        setIsShown(prevState => prevState - 1);       
      }
    }
    const openImg = event => {
      if (isopen) {
        setisopen(false)
      } else {
        setisopen(true)
      }
    }




    const handleSwipe = (direction) => {
      if (direction === 'left' && isShown < 3) {
          setIsShown(prevState => prevState + 1);
      } else if (direction === 'right' && isShown > 0) {
          setIsShown(prevState => prevState - 1);
      }
  };

  const handleTouchStart = (e) => {
      const touchStartX = e.touches[0].clientX;
      const touchStartY = e.touches[0].clientY;
      let direction = '';

      const handleTouchMove = (e) => {
          const touchEndX = e.touches[0].clientX;
          const touchEndY = e.touches[0].clientY;

          const diffX = touchStartX - touchEndX;
          const diffY = touchStartY - touchEndY;

          if (Math.abs(diffX) > Math.abs(diffY)) {
              if (diffX > 0) {
                  direction = 'left';
              } else {
                  direction = 'right';
              }
          }

          document.removeEventListener('touchmove', handleTouchMove);
      };

      document.addEventListener('touchmove', handleTouchMove);

      const handleTouchEnd = () => {
          handleSwipe(direction);
          document.removeEventListener('touchend', handleTouchEnd);
      };

      document.addEventListener('touchend', handleTouchEnd);
  };


  useEffect(()=>{
    // if (isClass === 'gallery-vid1' ) {
    //   setIsUrl('Nodo 11')
    // } else if (isClass === 'gallery-vid2') {
    //   setIsUrl('Nodo Trade')
    // } else {
    //   setIsUrl('x')
    // };

  if (window.location.pathname === '/'){
    window.history.pushState(null, null, `${window.location.pathname}${isUrl}`);

  }
  },[isUrl]

  )

  return (
    <div style={ visible ? {display : 'flex',flexDirection : 'row',flexWrap : 'wrap'} : {display : 'none',flexDirection : 'row',flexWrap : 'wrap'}}>                  
       
         <div className='place-holder'>
            <img className='left-icon' onClick={handleClicks} src="https://cdn-icons-png.flaticon.com/128/8213/8213500.png" alt="" />  
            {
                isShown === 0 ? (isopen? ( <video onClick={openImg} onTouchStart={handleTouchStart} loop autoPlay muted className='page-vid'>
                <source src={isClass === 'gallery-vid1' ? 'fantasythumb.mp4' : 'Probovid.mp4'} type='video/mp4'/>
              </video>):(<div className="popup">
                <div className="popup-inner">
                  <img onClick={openImg} style={{position: 'absolute', top: '10px', right: '10px',marginBottom : '10px',height : '25px',width : '25px'}} src="close.png" alt="" />
                  <video onClick={openImg} loop autoPlay muted className='page-vid'>
              <source src={isClass === 'gallery-vid1'? ('fantasythumb.mp4'):('Probovid.mp4')} type='video/mp4'/>
            </video>
                </div>
              </div>)
                   
                ) : (
                    isShown === 1 ? (
                      isopen? (<img onClick={openImg} onTouchStart={handleTouchStart} className= 'page-img' src={isClass === 'gallery-vid1'? ('Nod11-1.jpg'):('Probo-1.jpg')} alt="" />):(<div className="popup">
                      <div className="popup-inner">
                        <img onClick={openImg} style={{position: 'absolute', top: '10px', right: '10px',marginBottom : '10px',height : '25px',width : '25px'}} src="close.png" alt="" />
                        <img
                          src={isClass === 'gallery-vid1'? ('Nod11-1.jpg'):('Probo-1.jpg')}
                          alt=""
                          onClick={openImg}
                        />
                      </div>
                    </div>)
                        
                        ) : isShown === 2? (isopen? (<img onClick={openImg} onTouchStart={handleTouchStart} className= 'page-img' src={isClass === 'gallery-vid1'? ('Nod11-2.jpg'):('Probo-2.jpg')} alt="" />):(<div className="popup">
                        <div className="popup-inner">
                          <img onClick={openImg} style={{position: 'absolute', top: '10px', right: '10px',marginBottom : '10px',height : '25px',width : '25px'}} src="close.png" alt="" />
                          <img
                            src={isClass === 'gallery-vid1'? ('Nod11-2.jpg'):('Probo-2.jpg')}
                            alt=""
                            onClick={openImg}
                          />
                        </div>
                        
                      </div>)) : (
                          
                          isopen? (<img onClick={openImg} onTouchStart={handleTouchStart} className= 'page-img' src={isClass === 'gallery-vid1'? ('FantasyThumb.jpg'):('Probo-3.jpg')} alt="" />):(<div className="popup">
                          <div className="popup-inner">
                            <img onClick={openImg} style={{position: 'absolute', top: '10px', right: '10px',marginBottom : '10px',height : '25px',width : '25px'}} src="close.png" alt="" />
                            <img
                              src={isClass === 'gallery-vid1'? ('FantasyThumb.jpg'):('Probo-3.jpg')}
                              alt=""
                              onClick={openImg}
                            />
                          </div>
                          
                        </div>)
                        )
                )
            }
            <img className='right-icon' onClick={handleClick} src="https://cdn-icons-png.flaticon.com/128/8213/8213522.png" alt="" />
        </div>
        <div style={{display : 'flex',justifyContent : 'center',alignItems : 'center',width:'100%'}}>
        <div className='page-title'>
          <h2>{isClass === 'gallery-vid1'? ('Nod11'):('Nodo Trade')}</h2>
          <h1>{isClass === 'gallery-vid1'? ('Fantasy Cricket App [Android + IOS + Web]'):('Opinion Trading App Like Probo [Android + IOS]')}</h1>
        </div>
        </div>

        <div style={{display : 'flex',justifyContent : 'center',alignItems : 'center',width:'100%'}}>
        <div className='page-title'>
        <button onClick={()=> window.open("https://wa.me/918618468216", "_blank")} className='page-btn'>
      <img className='page-icon' src="whatsapp.png" alt="" />
      Buy On Whastapp
     </button>
     <button onClick={()=> window.open(fiverrDlink, "_blank")} className='page-btn'>
      <img className='page-icon' src="fiverr.png" alt="" />
      Buy On Fiverr
     </button>
     <button onClick={()=> window.open(demolink, "_blank")} className='page-btn'>
      <img className='page-icon' src="android.png" alt="" />
      Demo App
     </button>
     </div>
     </div>


        <div style={{display : 'flex',justifyContent : 'center',alignItems : 'center',width:'100%'}}>
          {/* just line */}
          <div style={{height : '2px',backgroundColor : 'white', width : '60%'}}></div>
        </div>
        <div style={{display : 'flex',justifyContent : 'center',alignItems : 'center',width:'100%'}}>
        <div className='page-title'>
          <h2 style={{fontSize : '20px'}}>Features</h2>
          <h1 style={{fontSize : '15px',whiteSpace : 'pre-wrap'}}>{isClass === 'gallery-vid1'? nod11Feature : nodoTradeFeature}</h1>
        </div>
        </div>
    </div>
  )
}
