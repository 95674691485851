// import React from 'react'

// export default function Galleryview() {
//   return (
//     <div className='gallery-flex-container'>
//       <div className='gallery-item1'>
//         <img className='gallery-img1' src="FantasyThumb.jpg" alt="" />
//         <video loop autoPlay muted className='gallery-vid1'>
//           <source src='fantasythumb.mp4' type='video/mp4'/>
//         </video>
//         <div className='item-title1'>
//           <h2>Nod11</h2>
//           <h1>Fantasy Cricket App</h1>
//         </div>
//       </div>
//       <div className='gallery-item2'>
//         <img className='gallery-img2' src="ProboThumbCut.jpg" alt="" />
//         <video loop autoPlay muted className='gallery-vid2'>
//           <source src='Probovid.mp4' type='video/mp4'/>
//         </video>
//         <div className='item-title2'>
//           <h2>Nodo Trade</h2>
//           <h1>Opinion Trading App</h1>
//         </div>
//       </div>
//     </div>
    
//   )

  
// }

// import React, { useState } from 'react';

// export default function Galleryview() {
//   const [hoveredItem, setHoveredItem] = useState(1);
//   return (
//     <div className='gallery-flex-container'>
//       <div className='gallery-item1' onMouseEnter={() => setHoveredItem(1)} onMouseLeave={() => setHoveredItem(1)}>
//       {window.innerWidth > 768 ? (  
//       hoveredItem !== 1 ? (
//         <img className='gallery-img1' src="FantasyThumb.jpg" alt="" />) : (
//         <video loop autoPlay muted className='gallery-vid1'>
//           <source src='fantasythumb.mp4' type='video/mp4'/>
//         </video>) ) : 
//         (
//           <video loop autoPlay muted className='gallery-vid1'>
//             <source src='fantasythumb.mp4' type='video/mp4'/>
//           </video>)
//         }
//         <div className='item-title1'>
//           <h2>Nodo11</h2>
//           <h1>Fantasy Cricket App</h1>
//         </div>
//       </div>
//       <div className='gallery-item2' onMouseEnter={() => setHoveredItem(2)} onMouseLeave={() => setHoveredItem(2)}>
//         {window.innerWidth > 768? (
//         hoveredItem !== 2 ? (
//           <img className='gallery-img2' src="ProboThumbCut.jpg" alt="" />
//         ) : (
//         <video loop autoPlay muted className='gallery-vid2'>
//           <source src='Probovid.mp4' type='video/mp4'/>
//         </video>
//         ) ) : 
//         (
//           <video loop autoPlay muted className='gallery-vid2' style={{width: "100%"}}>
//             <source src='Probovid.mp4' type='video/mp4'/>
//           </video>
//           )
//         }
//         <div className='item-title2'>
//           <h2>Nodo Trade</h2>
//           <h1>Opinion Trading App</h1>
//         </div>
//       </div>
//     </div>
    
//   )

  
// }


// import React, { useState } from 'react';

// export default function Galleryview() {
//   const [hoveredItem, setHoveredItem] = useState(1);
//   const [isMouseOver, setIsMouseOver] = useState(false);

//   return (
//     <div className='gallery-flex-container'>
//       <div
//         className={`gallery-item1 ${isMouseOver && 'expanded'}`}
//         onMouseEnter={() => {
//           setHoveredItem(1);
//           setIsMouseOver(true);
//         }}
//         onMouseLeave={() => setIsMouseOver(false)}
//       >
//         {window.innerWidth > 768 ? (
//           hoveredItem !== 1 ? (
//             <img className='gallery-img1' src="FantasyThumbCut.jpg" alt="" />
//           ) : (
//             <video loop autoPlay muted className='gallery-vid1'>
//               <source src='fantasythumb.mp4' type='video/mp4'/>
//             </video>
//           )
//         ) : (
//           <video loop autoPlay muted className='gallery-vid1'>
//             <source src='fantasythumb.mp4' type='video/mp4'/>
//           </video>
//         )}
//         <div className='item-title1'>
//           <h2>Nodo11</h2>
//           <h1>Fantasy Cricket App</h1>
//         </div>
//       </div>
//       <div
//         className={`gallery-item2 ${isMouseOver && 'expanded'}`}
//         onMouseEnter={() => {
//           setHoveredItem(2);
//           setIsMouseOver(true);
//         }}
//         onMouseLeave={() => setIsMouseOver(false)}
//       >
//         {window.innerWidth > 768 ? (
//           hoveredItem !== 2 ? (
//             <img className='gallery-img2' src="ProboThumbCut.jpg" alt="" />
//           ) : (
//             <video loop autoPlay muted className='gallery-vid2'>
//               <source src='Probovid.mp4' type='video/mp4'/>
//             </video>
//           )
//         ) : (
//           <video loop autoPlay muted className='gallery-vid2' style={{ width: "100%" }}>
//             <source src='Probovid.mp4' type='video/mp4'/>
//           </video>
//         )}
//         <div className='item-title2'>
//           <h2>Nodo Trade</h2>
//           <h1>Opinion Trading App</h1>
//         </div>
//       </div>
//     </div>
//   );
// }


import React, { useState } from 'react';

export default function Galleryview({isShown,handleClick}) {
  const [hoveredItem, setHoveredItem] = useState(1);
  // const [isShown, setIsShown] = useState(false);

  // const handleClick = event => {
  //   setIsShown(true);
  // }
  return (
    // <div style={{display : props.visible}} className='gallery-flex-container'>
    <div style={isShown ? { display : 'none'}:{display : 'flex'}} className='gallery-flex-container'>
      <div
        className={`gallery-item1`} onClick={handleClick}
        onMouseEnter={() => {
          setHoveredItem(1);
        }}
      >
        {window.innerWidth > 768 ? (
          hoveredItem !== 1 ? (
            <img className='gallery-img1' src="FantasyThumbCut.jpg" alt="" />
          ) : (
            <video loop autoPlay muted className='gallery-vid1'>
              <source src='fantasythumb.mp4' type='video/mp4'/>
            </video>
          )
        ) : (
          <video loop autoPlay muted className='gallery-vid1' style={{ width: "100%",position : 'relative'}}>
            <source src='fantasythumb.mp4' type='video/mp4'/>
          </video>
        )}
        <div className='item-title1'>
          <h2>Nod11</h2>
          <h1>Fantasy Cricket App</h1>
        </div>
      </div>
      <div
        className={`gallery-item2`} onClick={handleClick}
        onMouseEnter={() => {
          setHoveredItem(2);
        }}
      >
        {window.innerWidth > 768 ? (
          hoveredItem !== 2 ? (
            <img className='gallery-img2' src="ProboThumbCut.jpg" alt="" />
          ) : (
            <video loop autoPlay muted className='gallery-vid2'>
              <source src='Probovid.mp4' type='video/mp4'/>
            </video>
          )
        ) : (
          <video loop autoPlay muted className='gallery-vid2' style={{ width: "100%" , position : 'relative' }}>
            <source src='Probovid.mp4' type='video/mp4'/>
          </video>
        )}
        <div className='item-title2'>
          <h2>Nodo Trade</h2>
          <h1>Opinion Trading App</h1>
        </div>
      </div>

      
    </div>
    
  );
}
