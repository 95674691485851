import React, { useState } from 'react';

const Feedback = () => {
  const [email, setEmail] = useState('');
  const [text, setText] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleSubmit = () => {
    // Assuming you have a function to send email, you can call it here
    sendEmail(email, text);
    // Clearing the fields after submission
    setEmail('');
    setText('');
  };

  const sendEmail = (email, text) => {
    const subject = 'Feedback Submission';
  const body = `Email: ${email}\nFeedback: ${text}`;
  const mailtoLink = `mailto:noddevelopers@gmail.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

  window.location = (mailtoLink);
  };

  return (
    <div >
      <div style={{backgroundColor : 'rgb(187, 187, 187)' , height : '2px' , margin : '20px'}}></div>
      <h2 className='feedhead'>Tell us about what you think</h2>

    <div style={{ color: '#fff', fontFamily: 'All'}}>      
      <form className='formfeed' onSubmit={handleSubmit} style={{display: 'flex'}}>
        <div style={{display: 'flex',alignItems: 'center'}} >
          <label style={{ color: '#fff', margin :'20px', marginRight:'45px'}}>Email:</label>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            required
          />
        </div>
        <div style={{display: 'flex',alignItems: 'center'}}>
          <label style={{ color: '#fff' , margin :'20px' }}>Feedback:</label>
          <textarea
            value={text}
            onChange={handleTextChange}
            style={{
              minHeight: '100px',
            }}
            required
          />
        </div>
        <button
        className='mail-btn'
          type="submit"
        >
          Submit
        </button>
      </form>
    </div>
    </div>
  );
};

export default Feedback;
