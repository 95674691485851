import React from 'react'

export default function Navbar() {

  const handleButtonClick = () => {
    window.location = "https://noddev.com/";
  };
  return (

<nav className='Vnavbar'>
  <div className='fluid-container'>
    <button className='hhhhh' style={{display:'flex',alignItems:'center',width:'100%',backgroundColor:'rgba(52, 52, 52, 0.0)',border:'0px'}} onClick={handleButtonClick}>

  <a className="Vnavbar-brand" href="/">
      <img src="letter-n.png" alt="Logo" width="30" height="25" class="d-inline-block align-text-top"/>
       
     </a>
     <h2 className='nametitle'>Nod Developers</h2>
    </button>
     <div className='nav-incontainer'>
<button onClick={()=> window.open("https://wa.me/918618468216", "_blank")} className='contact-btn'>
  CONTACT
</button>
     </div>
  </div>
</nav>
  )
  }