import React from 'react'

export default function Points() {
  return (
    <div style={{display:'flex',justifyContent:'center'}}>
        <div className='points'>
        <h1 className='points-title'>Why You Should Have Our Recommended Web and App Solution? </h1>
        <h4 className='points-sub'>Fantasy sports/trading apps has witnessed a significant rise in popularity in recent years. With a vast fan base for cricket globally, investing in these app allows you to tap into this growing market.</h4>
      <div className='point-divv'>
        <div className='point-in'>
            <img className='points-img' src="arrow.png" alt="" />
        </div>
        <div className='points-indiv'>
        <h2 className='points-main-title'>Increased Demand</h2>
        <h5 className='points-main-sub'>The demand for these app platforms is continuously growing, with more users engaging with the apps.</h5>
        </div>
      </div>
      <div className='point-div'>
        <div className='point-in'>
            <img className='points-img' src="money.png" alt="" />
        </div>
        <div className='points-indiv'>
        <h2 className='points-main-title'>Revenue Generation</h2>
        <h5 className='points-main-sub'>We pick projects which have a proven track record of being profitable. You can generate revenue through apps. </h5>
        </div>
      </div>
      <div className='point-divv'>
        <div className='point-in'>
            <img className='points-img' src="social.png" alt="" />
        </div>
        <div className='points-indiv'>
        <h2 className='points-main-title'>Users Timeline</h2>
        <h5 className='points-main-sub'>Our choosen project's event happen throughout the year, you can offer more opportunities to fans/users.</h5>
        </div>
      </div>
      <div className='point-div'>
        <div className='point-in'>
            <img className='points-img' src="brands.png" alt="" />
        </div>
        <div className='points-indiv'>
        <h2 className='points-main-title'>Associate with Big Brands</h2>
        <h5 className='points-main-sub'>Investing in a these app can provide opportunities to associate with big brands and media outlets.</h5>
        </div>
      </div>
      <div className='point-divv'>
        <div className='point-in'>
            <img className='points-img' src="graph.png" alt="" />
        </div>
        <div className='points-indiv'>
        <h2 className='points-main-title'>Demographics and Audience </h2>
        <h5 className='points-main-sub'>These apps have gained immense popularity in recent years, attracting a diverse range of users.</h5>
        </div>
      </div>
      <div className='point-div'>
        <div className='point-in'>
            <img className='points-img' src="shield.png" alt="" />
        </div>
        <div className='points-indiv'>
        <h2 className='points-main-title'>Safe and Secure</h2>
        <h5 className='points-main-sub'>The demand for fantasy cricket app platforms is continuously growing, with more users engaging with the apps.</h5>
        </div>
      </div>
        </div>
    </div>
  )
}
